<!-- <template>
<div class="descrption dirr" :style="$vuetify.theme.dark ? '' : 'background-color: #f9fafb'">
    <p class="dirr tl d-none d-sm-flex head_title py-6">{{ $t("ataba_structure") }}</p>
    <hr class="hr">
    <div class="descCon pt-0" >
        <div class="nav d-none d-sm-flex">
            <div class="pt-8">
                <template v-for="(x, i) in descres" >
                    <p @click="showDesc(x.id)" class="tl py-4 nav_items" ><img class="mx-1" src="/assets/img/z-ali.svg" >{{x.title}}</p>
                </template>
            </div>
        </div>
        <div class="slider navbar d-flex d-sm-none" style="padding-top: 16px !important;" :class="{ 'hidden-navbar': !showNavbar }" >
            <p class="dirr tl">{{ $t("ataba_structure") }}</p>
            <v-btn-toggle v-model="toggle_desc">
            <swiper class="swiper dirr mt-6" ref="mySwiper3" :options="swiperOptions3">
                <swiper-slide v-for="(x, i) in descres" :key="x" style="width: 150px !important;">
                    <v-btn outlined class="src" style="width: auto!important;" :value="'desc'+(i+1)" @click="showDesc(x.id)">
                        <p class="tl py-3 px-4" style="width: auto;">{{x.title}}</p>
                    </v-btn>
                </swiper-slide>
            </swiper>
            </v-btn-toggle>
        </div>
        <div class="content" style="margin-top: 100px !important;" ref="content" :style="$vuetify.theme.dark ? '' : 'background-color: #f8f9fa'">
            <div class="contentCon">

                <v-skeleton-loader v-if="loading" class="border"  max-width="800" type="image, article" ></v-skeleton-loader>

                <template v-else v-for="(i, index) in showres" >

                    <p v-if="descres[i.parent_id]" class="tx content_list">{{ descres[i.parent_id].content }}</p>

                    <v-card class="mb-4 mx-2 content-card" :class="$vuetify.theme.dark ? 'dark-theme' : ''">
                        <v-card-title class="tl d-flex justify-space-between">
                            {{i.title}}
                        </v-card-title>
                        <hr>
                        <v-card-text>
                            <div class="tx" v-html="formatContent(i.content, index)"></div>
                            <v-btn 
                                v-if="i.content.length > 200"
                                text
                                cercle
                                color="#567530" 
                                @click="toggleExpand(index)"
                                class="mt-2 btn_expand"
                            >
                                {{ isExpanded[index] ? 'عرض أقل' : $t('view_more') }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </template>
                
            </div>
        </div>
    </div>
</div>
</template> 
    


<script>
const OFFSET = 200;
export default {
   metaInfo() {
    return {
     
    };
  },
    data() {
    return {
        loading: true,
        load: true,
        descres : [],
        showres: [],
        toggle_desc : 'desc1',
        swiperOptions3: {
            slidesPerView: 'auto',
            spaceBetween: 0,
            freeMode: true,
            keyboard: true,
        },
        showNavbar: true,
        lastScrollPosition: 0,
        scrollValue: 0,
        isExpanded: {},
    }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {  
        document.title = this.$t("ataba_structure");  
        this.lastScrollPosition = window.pageYOffset
        window.addEventListener('scroll', this.onScroll)
        const viewportMeta = document.createElement('meta')
        viewportMeta.name = 'viewport'
        viewportMeta.content = 'width=device-width, initial-scale=1'
        document.head.appendChild(viewportMeta);
    }, 
    created () {
        this.getDescs ();
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods:{
        getDescs () {
            this.$axios.get("structure/", {
            })
            .then(res => {
                this.load = false;
                this.descres = res.data;
                this.showDesc(res.data[0].id);
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        showDesc (x) {
            this.$axios.get("structure/"+x, {
            })
            .then(res => {
                this.loading = false;
                this.showres = res.data;
                this.isExpanded = {}; // Reset expansion state
                window.scrollTo(0,0);
                this.$refs.content.scrollTop = 0;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        onScroll () {
            if (window.pageYOffset < 0) {return}
            if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {return}
            this.showNavbar = window.pageYOffset < this.lastScrollPosition
            this.lastScrollPosition = window.pageYOffset
        },
        truncateText(text, length) {
            const strippedText = text.replace(/<[^>]*>/g, '');
            if (strippedText.length <= length) return text;
            return strippedText.substring(0, length) + '...';
        },
        formatContent(content, index) {
            if (!this.isExpanded[index]) return this.truncateText(content, 200);
            return content.replace(/\n/g, '<br>');
        },
        toggleExpand(index) {
            this.$set(this.isExpanded, index, !this.isExpanded[index]);
        },
    },
}
</script>
<style scoped>
/* f8f9fa */
.descrption .descCon .nav {
    max-width: 330px !important;
    border-left: 1px solid #ccc;

}

.content-card {
    transition: all 0.3s ease;
    border-radius: 8px;
}

.content-card.dark-theme {
    background-color: #1E1E1E;
    color: white;
}

.content-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
body * {
    font-family: 'readex'!important;
}
.tx {
    line-height: 1.6;
    padding: 8px 0;
}
.head_title {
    font-size: 32px;
    font-weight: 700;
}
.hr {
  height: 2px; 
  background: #ccc; 
  background: linear-gradient(to right, rgba(0,0,0,0) , #e0e4dc, rgba(0,0,0,0));
  border: 0;
}
.descrption::after {
    height: 50px!important;
}
.btn_expand {
    letter-spacing: 0; border: solid thin #567530; border-radius: 15px;
}
.nav_items {
    text-align: start; cursor: pointer; font-size: 15px;
}
.content_list {
    font-size: 15px!important;
    padding: 10px!important;
}
</style> -->




<template>
    <div class="page-wrapper" :style="$vuetify.theme.dark ? 'background: #292c2c' : ''">
      <header :style="$vuetify.theme.dark ? 'background-color: #000; background-shadow: none' : 'background: linear-gradient(to left, #f8f9fa 0%, #ffffff 100%)'">
        <div class="header-content" >
          <h1 :style="$vuetify.theme.dark ? 'color: #FFF' : 'color: #333'">{{ $t("ataba_structure") }}</h1>
        </div>
      </header>
      <nav class="mobile-nav">
        <ul>
          <li v-for="(section, index) in sections" :key="'mobile-'+index">
            <a :href="'#section' + (index + 1)" 
               :class="{ active: activeSection === 'section' + (index + 1) }"
               @click.prevent="scrollToSection('section' + (index + 1))">
              <img src="/assets/img/z-ali.svg" alt="icon" class="nav-icon">{{ section.title }}
            </a>
          </li>
        </ul>
      </nav>
      
      <div class="content-container" :style="$vuetify.theme.dark ? 'background-color: #292c2c' : ''">
        <nav class="sidebar">
          <ul class="nav-links" >
            <li v-for="(section, index) in sections" :key="'sidebar-'+index">
              <a :href="'#section' + (index + 1)" 
                :style="$vuetify.theme.dark ? 'color: #ccc' : 'color: #444'"
                 :class="{ active: activeSection === 'section' + (index + 1) }"
                 @click.prevent="scrollToSection('section' + (index + 1))">
                <img src="/assets/img/z-ali.svg" alt="icon" class="nav-icon"> {{ section.title }}
              </a>
            </li>
          </ul>
        </nav>
        
        <div class="main-content">
          <main>
            <section v-for="(section, sectionIndex) in sections" 
                    :id="'section' + (sectionIndex + 1)" 
                    :key="'section-'+sectionIndex" 
                    class="content-section">
              <h2 >{{ (sectionIndex + 1) + '. ' + section.title }}</h2>

              <div class="section-content">
                <p v-if="section.content" >{{ section.content }}</p>




                <!-- <div v-for="(item, itemIndex) in section.children" 
                     :key="'item-'+sectionIndex+'-'+itemIndex" 
                     class="section-item">
                  <h3>{{ item.title }}</h3>
                  <div class="section-item-content">
                    <p :class="{ 'expandable-text': true, 'expanded': item.expanded }">
                      {{ item.content }}
                    </p>
                    <button class="read-more-btn" @click="toggleExpand(sectionIndex, itemIndex)" >
                      {{ item.expanded ? 'عرض أقل' : $t('view_more') }}
                    </button>
                  </div>
                </div> -->


                <template v-for="(i, index) in section.children">
                    <v-card
                        class="mb-4 mx-2 content-card section-item"
                        :class="$vuetify.theme.dark ? 'dark-theme' : ''"
                    >
                        <v-card-title class="tl d-flex justify-space-between pa-6">
                        {{ i.title }}
                        </v-card-title>
                        <hr />
                        <v-card-text class="pa-6">
                        <div class="expandable-text" v-html="formatContent(i.content, sectionIndex, index)"></div>
                        <v-btn
                            v-if="i.content.length > 200"
                            text
                            cercle
                            color="#567530"
                            @click="toggleExpand(sectionIndex, index)"
                            class="mt-2 btn_expand"
                        >
                            {{ isExpanded[sectionIndex + '-' + index] ? 'عرض أقل' : $t('view_more') }}
                        </v-btn>
                        </v-card-text>
                    </v-card>
                </template>






              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    data() {
      return {
        activeSection: 'section1',
        sections: [],

        toggle_desc : 'desc1',
        scrollValue: 0,
        isExpanded: {}
      };
    },
    methods: {
      getDescs () {
          this.$axios.get("structure", {
          })
          .then(res => {
              this.load = false;
              this.sections = res.data;
          })
          .catch(() => {
          });
      },
    //   toggleReadMore(sectionIndex, itemIndex) {
    //     this.sections[sectionIndex].children[itemIndex].expanded = !this.sections[sectionIndex].children[itemIndex].expanded;
    //   },
      
      // scrollToSection(sectionId) {
      //   const element = document.getElementById(sectionId);
      //   if (element) {
      //     element.scrollIntoView({
      //       behavior: 'smooth'
      //     });
      //     this.activeSection = sectionId;
      //   }
      // },
      scrollToSection(sectionId) {
        const element = document.getElementById(sectionId);
        if (element) {
          const offset = 100; 
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });

          this.activeSection = sectionId;
        }
      },


        truncateText(text, length) {
            const strippedText = text.replace(/<[^>]*>/g, '');
            if (strippedText.length <= length) return text;
            return strippedText.substring(0, length) + '...';
        },
        formatContent(content, sectionIndex, childIndex) {
            const key = sectionIndex + '-' + childIndex;
            if (!this.isExpanded[key]) {
                return this.truncateText(content, 200);
            }
            return content.replace(/\n/g, '<br>');
        },
        toggleExpand(sectionIndex, childIndex) {
            const key = sectionIndex + '-' + childIndex;
            this.$set(this.isExpanded, key, !this.isExpanded[key]);
        },

      
      debounce(func, wait = 20, immediate = true) {
        let timeout;
        return function() {
          const context = this, args = arguments;
          const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
          };
          const callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) func.apply(context, args);
        };
      },
      
      handleScroll() {
        const scrollPosition = window.scrollY + window.innerHeight / 2;
        const sections = document.querySelectorAll('.content-section');
        let current = '';
        
        sections.forEach(section => {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.clientHeight;
          if (scrollPosition >= sectionTop && scrollPosition <= (sectionTop + sectionHeight)) {
            current = section.getAttribute('id');
          }
        });
        
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
          current = sections[sections.length - 1].getAttribute('id');
        }
        
        if (current) {
          this.activeSection = current;
        }
      }
    },
    created() {
      this.getDescs();
    },
    mounted() {
      const debouncedHandleScroll = this.debounce(this.handleScroll.bind(this), 10);
      
      window.addEventListener('scroll', debouncedHandleScroll);
      
      this.handleScroll();
      
      this._scrollListener = debouncedHandleScroll;
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this._scrollListener);
    }
  };
  </script>
  <style scoped>
    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.btn_expand {
    letter-spacing: 0; border: solid thin #567530; border-radius: 15px;
}
.content-card {
    transition: all 0.3s ease;
    border-radius: 8px;
}

.content-card.dark-theme {
    background-color: #1E1E1E;
    color: white;
}

.content-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
html {
    scroll-behavior: smooth;
}

body {
    line-height: 1.6;
    color: #333;
    background-color: #f8f9fa;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(to left, #f8f9fa 0%, #ffffff 100%);
}

.sidebar {
    width: 280px;
    flex-shrink: 0;
    color: #333;
    background-color: transparent;
    padding: 20px 10px;
    height: calc(100vh - 80px); 
    position: sticky;
    top: 80px; 
    overflow-y: auto;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
    right: 0;
    border-left: 1px solid #eaeaea;
}

.nav-links {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 15px 0;
}

.nav-links li {
    margin: 5px 0;
}

.nav-links a {
    /* color: #444; */
    text-decoration: none;
    font-weight: 500;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border-right: 3px solid transparent;
    border-radius: 0 4px 4px 0;
    margin: 0 3px;
}

.nav-links a:hover, .nav-links a.active {
    background-color: rgba(176, 188, 137, 0.1);
    color: #567530;
    border-right: 3px solid #567530;
}

.nav-links a.active {
    font-weight: bold;
    background-color: rgba(176, 188, 137, 0.15);
}

.nav-links a i {
    margin-left: 12px;
    color: #567530;
    width: 20px;
    text-align: center;
    font-size: 1.1rem;
}

.nav-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    vertical-align: middle;
}

.main-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    transition: margin 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

header {
    width: 100%;
    /* background-color: #f5f5f5; */
    position: relative;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    text-align: center;
    /* background: linear-gradient(to bottom, #ffffff, #f8f9fa); */
    border-bottom: none;
}

.header-content {
    text-align: center;
    padding: 0 20px;
}

header::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(86, 117, 48, 0.3), transparent);
}

header h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 25px;
    position: relative;
    font-weight: 600;
    margin: 25px 0;
}

.content-section {
    position: relative;
    margin: 0 40px 80px;
    background: none;
    padding: 0;
}

.content-section h2 {
    font-size: 1.4rem;
    /* color: #333; */
    position: relative;
    display: block;
    border: none;
    padding: 0 0 15px 0;
    font-weight: 700;
    /* text-align: right; */
}

.content-section h2::before {
    content: attr(data-number);
    position: absolute;
    right: -60px;
    top: 5px;
    font-family: 'Tajawal', serif;
    font-weight: 700;
    font-size: 3.5rem;
    color: rgba(86, 117, 48, 0.15);
    z-index: -1;
}


.section-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    padding: 0;
    border: none;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section-item h3 {
    color: #333;
    font-size: 1.3rem;
    padding: 25px 25px 20px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    font-weight: 400;
    border-radius: 0;
    position: relative;
    display: flex;
    align-items: center;
}

.section-item h3::before {
    content: ''; 
    background-image: url('/assets/img/book.png'); 
    background-size: 16px 16px; 
    background-position: center;
    background-repeat: no-repeat;
    font-size: 1rem;
    margin-left: 12px;
    width: 30px;
    height: 30px;
    background-color: rgba(86, 117, 48, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-item-content {
    padding: 25px 30px 30px;
    line-height: 1.7;
}

.section-item p {
    color: #555;
    line-height: 1.8;
    font-size: 0.9rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.short-text, .full-text {
    margin-bottom: 20px;
    line-height: 1.8;
}

.full-text {
    padding: 15px 0;
    border-right: 3px solid rgba(176, 188, 137, 0.3);
    padding-right: 15px;
    text-align: justify;
    display: none;
    color: #444;
    background-color: rgba(176, 188, 137, 0.03);
    border-radius: 0 5px 5px 0;
    margin-top: 10px;
}

.read-more-btn {
    background-color: #ffffff;
    color: #567530;
    border: 1px solid #567530;
    padding: 8px 24px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 0.95rem;
    display: inline-block;
    margin-top: 15px;
    font-weight: 500;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.read-more-btn:hover {
    background-color: #567530;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.content-section {
    animation: fadeIn 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    animation-fill-mode: both;
}

#section1 { animation-delay: 0.1s; }
#section2 { animation-delay: 0.2s; }
#section3 { animation-delay: 0.3s; }
#section4 { animation-delay: 0.4s; }
#section5 { animation-delay: 0.5s; }

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 992px) {
    header h1 {
        font-size: 2.4rem;
    }
    
    .content-section {
        margin: 0 30px 60px;
    }
    
    .content-section h2 {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .main-content {
        margin-right: 0;
        padding: 0 20px 40px;
    }

    .sidebar {
        transform: translateX(100%);
        width: 100%;
        height: auto;
        position: relative;
    }
    
    .sidebar.active {
        transform: translateX(0);
    }
    
    header h1 {
        font-size: 2rem;
    }
    
    .content-section h2 {
        font-size: 1.8rem;
    }
    
    .section-item {
        margin-bottom: 30px;
    }
}

@media (max-width: 480px) {
    header {
        padding: 10px 20px;
    }

    header {
        margin-bottom: 0px;
    }
    
    header h1 {
        font-size: 1.3rem;
    }
    
    .content-section {
        margin: 0 4px 40px;
    }
    
    .content-section h2 {
        font-size: 1.2rem;
    }
    
    .section-item h3 {
        font-size: 1.1rem;
        padding: 20px 20px 15px;
    }
    
    .section-item-content {
        padding: 20px;
    }
    
    .read-more-btn {
        padding: 6px 18px;
        font-size: 0.9rem;
    }
}

.content-container {
    display: flex;
    flex: 1;
}

.expandable-text {
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.3s ease;
    margin-bottom: 10px;
}

.expanded {
    -webkit-line-clamp: unset;
}

.content-container {
    max-width: 1350px;
    margin: 0 auto;
    display: flex;
    padding: 0 20px;
}

@media screen and (min-width: 768px) {
.section-content {
    display: block;
    margin-top: 0;
    position: relative;
    padding-right: 30px;
    border-right: 1px solid rgba(86, 117, 48, 0.15);
}

.section-content::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 3px solid #567530;
    border-radius: 50%;
    right: -8px;
    top: 0;
}
    
}

.mobile-nav {
    display: none;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    position: sticky;
    top: 0;
    z-index: 1000;
    margin-bottom: 20px;
    scroll-behavior: smooth;
    scrollbar-width: thin; 
    scrollbar-color: #a5a5a5 transparent; 
    -ms-overflow-style: scrollbar; 
    padding-left: 15px;
    padding-right: 15px;
}

.mobile-nav::-webkit-scrollbar {
    height: 5px;
    display: block;
}

.mobile-nav::-webkit-scrollbar-track {
    background: transparent;
}

.mobile-nav::-webkit-scrollbar-thumb {
    background-color: #a5a5a5; 
    border-radius: 20px;
}

.mobile-nav::before,
.mobile-nav::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 25px;
    z-index: 2;
    pointer-events: none;
}

.mobile-nav::before {
    left: 0;
    background: linear-gradient(to right, #fff 30%, rgba(255,255,255,0));
}

.mobile-nav::after {
    right: 0;
    background: linear-gradient(to left, #fff 30%, rgba(255,255,255,0));
}

.mobile-nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
}

.mobile-nav li {
    margin: 0 10px;
}

.mobile-nav a {
    display: flex;
    align-items: center;
    color: #303030;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: all 0.3s;
}

.mobile-nav a:hover,
.mobile-nav a.active {
    background-color: rgba(86, 117, 48, 0.1);
    color: #567530;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .sidebar {
        display: none;
    }
    
    .mobile-nav {
        display: block;
    }
    
    .main-content {
        width: 100%;
        padding: 0 15px;
    }
    
    .content-container {
        flex-direction: column;
    }
}
  </style>
